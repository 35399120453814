import React from 'react'
import styled from 'styled-components'

const StyledAboutSection = styled.section`
    max-width: 900px;
    padding: 25px 0;
`;

const StyledContent = styled.div`
    ul {
        display: grid;
        grid-template-columns: repeat(2, minmax(140px, 200px));
        padding: 0;
        margin: 20px 0 0 0;
        overflow: hidden;
        list-style: none;

        li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 20px;
        font-family: var(--link-font);
        font-size: var(--fs-regular);

            &:before {
                content: '•';
                position: absolute;
                left: 0;
                color: var(--fc-accent-color-1);
                font-size: var(--fs-small);
                line-height: 12px;
            }
        }
    }
`;

const About = () => {
    const skills = ['JavaScript (ES6)', 'HTML & (S)CSS', 'React', 'Python', 'Node.js', 'Next.js'];

    return (
        <StyledAboutSection id="about">
            <h2 className="section-header">About</h2>
            <div id="inner">
                <StyledContent> 
                    <div> 
                        <p>Hello! I'm <mark style={{padding: "0.1em 0.4em"}}>Evander</mark>. I'm a South Florida-based software engineer working primarily with JavaScript and JS-based frameworks.</p>
                        <p>I have a passion for technology and building things, both on and off the web, and I enjoy creating thoughtful, performance-focused software.</p>
                        <p>I graduated from Florida Atlantic University in 2020 with a bachelor of science in computer science and am returning shortly to pursue my master of science in artifical intelligence.</p>
                        <p>Here are some technologies I've been working with lately:</p>
                    </div>
                    <ul>
                        {skills && skills.map((skill, i) => <li key={i}>{skill}</li> )}
                    </ul>
                </StyledContent>
            </div>
        </StyledAboutSection>
    )
}

export { About }
