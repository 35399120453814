import React from 'react'
import styled from 'styled-components'

const StyledProjectsSection = styled.section`
    max-width: 900px;
    padding: 25px 0;

    .projects-container {
        display: grid;
        position: relative;
        margin-top: 50px;
    }
`;

const StyledProject = styled.div`
    cursor: default; 
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    border-bottom: 1px solid var(--border-color);

    .project-inner { 
        display: flex;
        justify-content: space-between;
        flex-direction: column; 
        align-items: flex-start; 
        position: relative; 
        height: 100%; 
        padding: 2rem 1.75rem; 
        box-shadow: 0 10px 30px -15px var(--navy-shadow);
        transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);

    }

    header {
        width: 100%; 
        margin-bottom: 15px;

        .project-top {
            margin-bottom: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            .folder {
                color: var(--fc-accent-color-2);

                svg {
                    height: 40px;
                    width: 40px;
                }
            }
        }
    
        .project-links {
            display: flex;
            align-items: center;
            margin-right: -10px;
            color: var(--fc-accent-color-1);
    
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 7px;
              
                &.external {
                    svg {
                    width: 22px;
                    height: 22px;
                    margin-top: -4px;
                    }
                }
    
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
          }
        }

        .project-title {
            font-family: var(--display-font);
            margin: 0 0 10px;
            font-size: 22px;
        }

        .project-description {
            font-size: 17px;
        }

        .keyword-list {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
            flex-wrap: wrap;
            padding: 0;
            margin: 20px 0 0 0;
            list-style: none;

            li {
              font-family: var(--link-font);
              font-size: var(--fs-xsmall);
              color: var(--fc-accent-color-2);
              line-height: 1.75;

              &:not(:last-of-type) {
                margin-right: 15px;
              }
            }
        }
    }
`;

const Projects = () => {
    const projectsData = [
        {
          title: 'Personal Website v2', 
          description: 'Redesigned my website to look more professional, modern, and overall cleaner. I also wanted to utilize a framework to keep inline with what I currently work with and to improve performance. This was built entirely with Gatsby.js, instead of vanilla JS, CSS, and HTML.',
          keywords: [ 'Gatsby.js', 'SCSS', 'JS', 'Styled-Components' ],
          siteLink: 'https://evander.co',
          githubLink: 'https://github.com/evandermendonca/v2'
        }, 
        {
          title: 'Goodreads Integration', 
          description: 'A web app that I created to display user information from Goodreads using Next.js and the Goodreads API. I wanted to present the information in a more aestically pleasing and trimmed-down manner than what Goodreads offers. I also wanted to improve my skills with Next.js.',
          keywords: [ 'Next.js', 'SCSS', 'Goodreads API', 'XML' ],
          siteLink: 'https://goodreads.vercel.app/',
          githubLink: 'https://github.com/evandermendonca/goodreads'
        }, 
        {
          title: 'Personal Website v1', 
          description: 'The first iteration of my personal site––created to give my work a digital home. At the time, I did not know much about web design or creating modern websites, so I did not use a framework. I do feel that I learned a lot about HTML, CSS, and web development overall, however. My skills since then have improved greately, including SEO and improving site performance.',
          keywords: [ 'HTML', 'CSS', 'JS' ],
          siteLink: 'https://evandermendonca.github.io/v1/',
          githubLink: 'https://github.com/evandermendonca/v1'
        }, 
        {
          title: 'Owlboard', 
          description: 'A social media app and complaint ticketing system that I worked on for my principles of software engineering course during my sophomore year of college. This app was created to give students a way to connect with each other and report about happenings going on around campus––negative or positive. We did not use any frameworks, instead using vanilla PHP. I did learn a lot about developing software in a team, and using SCRUM and agile methods to reach project completion. I was responsible for both front-end and back-end work.',
          keywords: [ 'PHP', 'JS', 'CSS', 'SQL' ],
          siteLink: 'https://lamp.cse.fau.edu/~cen4010fal19_g08/',
          githubLink: 'https://github.com/evandermendonca/owlboard'
        }, 
    ];

    return (
        <StyledProjectsSection id="projects">
            <h2 className="section-header">Projects</h2>

            <div className="projects-container">
                {projectsData && projectsData.map(({ title, description, keywords, siteLink, githubLink }, i) => {
                    return(
                        <StyledProject key={i} tabIndex="0">
                            <div className="project-inner">
                                <header>
                                    <div className="project-top">
                                        <div class="folder">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="img"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="1"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-folder">
                                                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                                            </svg>
                                        </div>
                                        <div className="project-links">
                                            {githubLink && (
                                                <a href={githubLink} aria-label="Github Link">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        role="img"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-github">
                                                        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                                                    </svg>
                                                </a>
                                            )}
                                            {siteLink && (
                                                <a href={siteLink} aria-label="external" className="external">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        role="img"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-external-link">
                                                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                        <polyline points="15 3 21 3 21 9"></polyline>
                                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                                    </svg>
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    <h3 className="project-title">{title}</h3>

                                    <div className="project-description">
                                        {description}
                                    </div>
                                </header>

                                <footer>
                                    {keywords && (
                                        <ul className="keyword-list">
                                            {keywords.map((keyword, i) => (
                                                <li key={i}>{keyword}</li>
                                            ))}
                                        </ul>
                                    )}
                                </footer>
                            </div>
                        </StyledProject>
                    );
                })}
            </div>
        </StyledProjectsSection>
    )
}

export { Projects }
