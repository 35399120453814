import React from 'react'
import styled from 'styled-components'

const StyledHeroSection = styled.section`
    flex-direction: column; 
    align-items: flex-start; 
    min-height: 60vh;

    @media (max-width: 600px) {
        padding: 25px 0 50px 0;
    }

    h1 {
        margin: 0 0 15px 4px; 
        font-family: var(--body-font);
        font-weight: 300; 
        font-size: clamp(var(--fs-small), 5vw, var(--fs-large));
    }

    p { 
        font-size: var(--fs-large); 
        font-weight: 300;
        margin: 30px 0 0;
        max-width: 500px;
    }

    .action-button {
        display: inline-block; 
        margin: 30px 5px;
        border: none;
        background-color: var(--fc-accent-color-1);
        color: #fff;
        border-radius: 38px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .02em;
        padding: 0.5em 2.25em;
        text-decoration: none;
        text-align: center;
        transform: scale(1);
        transition: color 225ms cubic-bezier(0.29, 0.19, 0.405, 1),transform 225ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        line-height: 2.2;
        min-width: 125px;
        box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 15%);
        will-change: transform;
        width: 150px;

        &:hover, 
        &:focus {
            background: var(--fc-accent-color-2);
            transform: scale(1.03);
        }
    }

    #resume-button {
        @media (max-width: 768px) {
            display: none;
        }
    }
`;


const Hero = () => {
    const one = <h1>My name is</h1>; 
    const two = <h2 className="large-heading"><mark>Evander Mendon&#231;a</mark></h2>; 
    const three = (<p>
        I'm a software engineer based out of South Florida, who enjoys building thoughtful, well-designed websites and applications.
    </p>);
    const four = (
        <div class="link-container">
            <a href="mailto:evander.m.mendonca@gmail.com" className="action-button">Contact Me</a>
            <a href="/resume.pdf" id="resume-button" className="action-button">Resume</a>
        </div>
    );

    const items = [one, two, three, four];
    
    return (
        <StyledHeroSection style={{padding: "50px 0 50px 0"}}>
            {items.map((item, i) => (
                <div>{item}</div>
            ))}
        </StyledHeroSection>
    )
}

export { Hero }