import React from 'react'
import { Layout } from '../components/layout'
import { Hero } from '../components/hero'
import { About } from '../components/about'
import { Projects } from '../components/projects'
import { Experience } from '../components/experience'
import { Contact } from '../components/contact'
 
const index = ({ location }) => {
  return (
    <Layout location={location}>
      <main>
        <Hero />
        <About />
        <Projects />
        <Experience />
        <Contact />
      </main>
    </Layout>
  )
}

export default index
