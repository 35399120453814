import React, { useState, useRef } from 'react'
import styled from 'styled-components'

const StyledExperienceSection = styled.section`
    max-width: 900px;
    
    .inner {
        display: flex;
        
        @media (max-width: 600px) {
            display: block;
        }
    }
`;

const StyledJobList = styled.ul`
    position: relative; 
    z-index: 3; 
    width: max-content; 
    padding: 0; 
    margin: 0;
    list-style: none;

    @media (max-width: 600px) {
        display: flex; 
        overflow-x: auto; 
        width: calc(100% + 100px); 
        margin-left: -50px;
        margin-bottom: 30px;
    }

    @media (max-width: 480px) {
        width: calc(100% + 50px); 
        margin-left: -25px;
    }

    li {
        &:first-of-type {
            @media (max-width: 600px) {
                margin-left: 50px;
            }

            @media (max-width: 480px) {
                margin-left: 25px;
            }

            &:last-of-type {
                @media (max-width: 600px) {
                    padding-right: 50px;
                }

                @media (max-width: 480px) {
                    padding-right: 25px;
                }
            }
        }
    }
`;

const StyledJobButton = styled.button`
    text-decoration: none; 
    text-decoration-skip-ink: auto; 
    cursor: pointer; 
    position: relative; 
    display: flex; 
    align-items: center; 
    width: 100%; 
    height: 75px;
    background-color: transparent;
    border: none;
    outline: none;
    white-space: nowrap;
    
    color: ${({ tabIndex }) => 
        tabIndex === '0' ? 'var(--fc-accent-color-2)' : '#000'
    };

    border-bottom: ${({ tabIndex }) => 
        tabIndex === '0' ? '1px solid var(--fc-accent-color-2)' : '1px solid var(--border-color);'
    };

    &:hover,
    &:focus {
        background-color: var(--offwhite-hover-color);
        color: var(--fc-accent-color-2);
        border-bottom: 1px solid var(--fc-accent-color-2);
    }

`; 

const StyledJobDescription = styled.div`
    width: 100%;
    height: auto;
    padding-left: 30px;

    @media (max-width: 768px) {
        padding-left: 20px;
    }

    @media (max-width: 600px) {
        padding-left: 0;
    }

    h3 {
        margin-bottom: 5px;
    }

    span {
        font-family: var(--display-font);
        font-size: clamp(var(--fs-xlarge), 5vw, var(--fs-small));
    }

    #employer-name {
        color: var(--fc-accent-color-2);
    }

    p {
        margin: 5px 0;
        font-size: 15px;
    }

    .job-description {
        margin-top: 20px;

        ul {
            padding: 0px; 
            margin: 0px; 
            list-style: none; 
            font-size: var(--fs-medium);

            li {
                position: relative; 
                margin-bottom: 10px;
                padding-left: 25px;
            }
        }
    }
`;

const Experience = () => {
    const experienceData = [
        {
          employer: 'Allusion Software', 
          title: 'Software Engineer',
          dateRange: 'May 2021 - Present',
          description: [ 
                        'Primarily responsible for front-end development using React, React Native, and TypeScript, along with some back-end development using Node.',
                        'Maintain a UI component library and create new components for the library for use in future projects.',
                        "Create custom software specific to a client's needs, including CRMs, tracking apps, and messaging apps.",
                        'Handle and store data using NoSQL via Firebase, i.e. Firestore, Authentication, Cloud Functions, etc.',
                        'Experience with software development best practices, including scrum, sprints, agile methodology, and version control via Github.'
                        ],
        }, 
        {
          employer: 'Genesis', 
          title: 'Software Engineer',
          dateRange: 'October 2020 - May 2021',
          description: [ 
                        'Primarily responsible for back-end development, using C# and ASP.NET, with some front-end work using React and TypeScript.',
                        'Create API endpoints that are consumable via REST.',
                        'Create and manage database schema using MySQL and SQL Server Management Studio.',
                        'Create and maintain a method for data communication between our system and a third-party system.',
                        'Integrate third-party APIs into our system, i.e. Zapier, Twilio, SendGrid, etc.',
                        'Create new features in collaboration with the management, sales, and marketing teams.',
                        'Use software development best practices, including version control via Github, scrum, and two week code sprints with regular reviews.'
                        ],
        }, 
        {
            employer: 'Equity Finders Pro', 
            title: 'Software Engineer Intern',
            dateRange: 'February 2020 - July 2020',
            description: [ 
                        "Assigned project while working with EFP: create a section of the user's dashboard within the CRM to allow them to communicate with clients en masse, using APIs such as SendGrid, Zapier, Twilio, PostCards, and DocuSign.",
                        'Create front-end UI components using PHP via the Laravel framework and third-party APIs.',
                        "Handle data using REST APIs, MySQL, and CRUD within Laravel's MVC framework to build out the user's dashboard.",
                        'Practice software development best practices, including scrum, code sprints, and version control using tools such as GitHub and Asana.',
                        'Communicate daily with a multidisciplinary team of engineers, designers, and QA testers using tools such as Zoom and Slack.'
                          ],
        }
    ];

    const [activeTabId, setActiveTabId] = useState(0);
    const tabs = useRef([]);

    return (
        <StyledExperienceSection id="experience">
            <h2 className="section-header">Experience</h2>
            <div className="inner">
                <StyledJobList>
                    {experienceData && experienceData.map(({ employer }, i) => {
                        return(
                            <StyledJobButton
                                isActive={activeTabId === i}
                                onClick={() => setActiveTabId(i)}
                                ref={el => (tabs.current[i] = el)}
                                id={`job-tab-${i}`}
                                role="job-tab"
                                aria-selected={activeTabId === i ? true : false}
                                aria-controls={`description-${i}`}
                                tabIndex={activeTabId === i ? '0' : '-1'}>
                                <li key={i}>{employer}</li>  
                            </StyledJobButton>
                        );
                    })}
                </StyledJobList>

                {experienceData && experienceData.map(({title, employer, dateRange, description}, i) => {
                    return(
                        <StyledJobDescription 
                        id={`description-${i}`}
                        role="job-description"
                        tabIndex={activeTabId === i ? '0' : '-1'}
                        aria-labelledby={`job-tab-${i}`}
                        aria-hidden={activeTabId !== i}
                        hidden={activeTabId !== i}>
                            <h3>
                                <span>{title}</span>
                                <span id="employer-name">&nbsp;w/ {employer}</span>
                            </h3>
                            <p>{dateRange}</p>
                            <div className="job-description">
                                <ul>
                                {description && description.map((currElement, index) => {
                                    return(
                                    <li key={index}>      
                                        {currElement}
                                    </li>
                                    );
                                })}
                                </ul>
                            </div>
                        </StyledJobDescription>
                    ); 
                })}
            </div>
        </StyledExperienceSection>
    )
}

export { Experience } 
