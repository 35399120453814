import React from 'react'
import styled from 'styled-components'

const StyledContactSection = styled.section`
    max-width: 600px;
    margin: 0 auto 100px;
    text-align: center;
    margin-bottom: 0;

    @media (max-width: 768px) {
        margin: 0 auto 50px;
    }
    
    .action-title {
        font-size: clamp(30px, 5vw, 40px);
        font-weight: 300;
    }

    .action-button {
        display: inline-block; 
        margin: 30px 5px;
        border: none;
        background-color: var(--fc-accent-color-1);
        color: #fff;
        border-radius: 38px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .02em;
        padding: 0.5em 2.25em;
        text-decoration: none;
        text-align: center;
        transform: scale(1);
        transition: color 225ms cubic-bezier(0.29, 0.19, 0.405, 1),transform 225ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        line-height: 2.2;
        min-width: 125px;
        box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 15%);
        will-change: transform;
        width: 150px;

        &:hover, 
        &:focus {
            background: var(--fc-accent-color-2);
            transform: scale(1.03);
        }
    }
`;

const Contact = () => {
    return (
        <StyledContactSection id="contact">
            <h2 className="section-header">Contact</h2>
            
            <h2 className="action-title">Feel free to reach out to me</h2>
            
            <p style={{padding: "0 10px"}}>
                I'm always open to hearing about new opportunties or chances for work, answering questions, or just saying hi. You can message or contact me anytime via email or LinkedIn.
            </p>

            <a href="mailto:evander.m.mendonca@gmail.com" className="action-button">
                Write to Me
            </a>
        </StyledContactSection>
    )
}

export { Contact }
